function homeRoomInit() {
    let allTitles = $(".room-title");
    let allContents = $(".room-detail-item");
    let allMobileContents = $(".room-content-mobile");

    $(".room-title").each(function (index) {
        let _this = $(this);
        let siblingTitles = allTitles.not(_this);
        let content = allContents.eq(index);
        let mobileContent = _this.next();
        let siblingContents = allContents.not($(content));
        let siblingMobileContents = allMobileContents.not($(mobileContent));
        if ($(window).width() > 1024) {
            _this.on("mouseenter", toggleContent);
        } else {
            _this.on("click", toggleContent);
        }

        function toggleContent(e) {
            e.preventDefault();
            siblingTitles.removeClass("active");
            _this.toggleClass("active");
            $(window).width() > 1024
                ? (siblingContents.hide(), content.fadeIn(700))
                : (siblingMobileContents.slideUp(),
                  mobileContent.slideToggle(700));
        }
    });

    if ($(window).width() < 1025) {
        $(".room-title").eq(0).trigger("click");
    } else {
        $(".room-title").eq(0).trigger("mouseenter");
    }
}

export default homeRoomInit;
