const historyYearDotSlider = new Swiper(
    ".history-year-dot-slider .swiper-container",
    {
        slidesPerView: 7,
        observeParents: true,
        observer: true,
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        breakpoints: {
            768: {
                slidesPerView: 4,
            },
        },
    }
);

const historyYearContentSlider = new Swiper(
    ".history-year-content-slider .swiper-container",
    {
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
            prevEl: ".history-year-content-slider .swiper-prev",
            nextEl: ".history-year-content-slider .swiper-next",
        },
        observeParents: true,
        observer: true,
        thumbs: {
            swiper: historyYearDotSlider,
        },
        on: {
            slideChange: function () {
                let activeIndex = this.activeIndex + 1;

                let nextSlide = $(
                    `.history-year-dot-slider .swiper-slide:nth-child(${
                        activeIndex + 1
                    })`
                );
                let prevSlide = $(
                    `.history-year-dot-slider .swiper-slide:nth-child(${
                        activeIndex - 1
                    })`
                );

                if (nextSlide && !nextSlide.hasClass("swiper-slide-visible")) {
                    this.thumbs.swiper.slideNext();
                } else if (
                    prevSlide &&
                    !prevSlide.hasClass("swiper-slide-visible")
                ) {
                    this.thumbs.swiper.slidePrev();
                }
            },
        },
    }
);

const aboutGallerySliderInit = () => {
    $(".about-gallery-slider").each(function (index) {
        var $this = $(this);
        $this.addClass("about-gallery-slider-" + index);
        $this
            .find(".swiper-prev")
            .addClass("about-gallery-slider-prev-" + index);
        $this
            .find(".swiper-next")
            .addClass("about-gallery-slider-next-" + index);

        var aboutGallerySlider = new Swiper(
            ".about-gallery-slider-" + index + " .swiper-container",
            {
                slidesPerView: 1,
                spaceBetween: 20,
                navigation: {
                    prevEl: ".about-gallery-slider-prev-" + index,
                    nextEl: ".about-gallery-slider-next-" + index,
                },
                pagination: {
                    el:
                        ".about-gallery-slider-" +
                        index +
                        " .swiper-pagination",
                    clickable: true,
                },
            }
        );
    });
};

const useSlide = () => {
    return {
        aboutGallerySliderInit,
    };
};

export default useSlide;
