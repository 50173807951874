export default function homeBannerSlider() {
    const homeBannerSlider = new Swiper(
        ".home-banner-slider .swiper-container",
        {
            speed: 1300,
            effect: "fade",
            fadeEffect: { crossFade: true },
            loop: true,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: ".home-banner-slider  .swiper-pagination",
                clickable: true,
            },
        }
    );
}
