import headerInit from "./headerInit";
import homeBannerSlider from "./homeBannerSlider";
import homeRoomInit from "./homeRoomInit";
import homeServiceSlider from "./homeServiceInit";
import newsSliderInit from "./newsSliderInit";
import homeFacilitySlider from "./homeFacilitySlider";
import homeStaffSlider from "./homeStaffSlider";
import appendFormBtn from "./appendFormBtn";
import useSlide from "./Slide";

const { aboutGallerySliderInit } = useSlide();

var lazyLoadInstance = new LazyLoad({
    // Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;

jQuery(function () {
    headerInit();
    homeBannerSlider();
    homeRoomInit();
    newsSliderInit();
    aboutGallerySliderInit();
    $(".home-news-tabs").tabslet();
    appendFormBtn();

    $(".back-to-top").on("click", function () {
        $("html, body").animate({ scrollTop: 0 }, 1000);
    });
    $.fancybox.defaults.backFocus = false;
    $.fancybox.defaults.parentEl = "form";
    if ($(".popup-form").length) {
        $(".popup-form-container").append($(".form-in-popup"));
    }
    // $.fancybox.open($("#popup_1"));
    $(".step-content").tabslet();
    $(".about-gallery-content").tabslet();
    var scrollBarSpy = document.querySelector(".about-sub-nav .sub-nav");
    var ms = new MenuSpy(scrollBarSpy, {
        threshold: $(".global-header").height() + $(".about-sub-nav").height(),
    });
    $(".about-sub-nav a").each(function () {
        let id = $(this).attr("href");
        $(this).on("click", function () {
            scrollToElement(id);
        });
    });
});

var scrollToElement = function (el, ms) {
    var speed = ms ? ms : 2000;
    let height = $(el).offset().top - $("header").outerHeight();
    if ($(".about-sub-nav").length) {
        height =
            $(el).offset().top -
            $("header").outerHeight() -
            $(".about-sub-nav").outerHeight();
    }
    $("html,body").animate(
        {
            scrollTop: height,
        },
        speed
    );
};
