function headerInit() {
    const menuMapping = new MappingListener({
        selector: ".menu-wrapper",
        mobileWrapper: ".mobile-menu-container",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-bottom-container",
        desktopMethod: "appendTo",
        breakpoint: 1024,
    }).watch();

    $(".menu-toggle").on("click", function () {
        $(".mobile-menu").fadeIn();
        $(".mobile-menu").addClass("is-open");
    });

    $(".search-toggle").on("click", function () {
        $(".search-wrapper").slideToggle();
    });

    $(".mobile-menu-overlay").on("click", function () {
        $(".mobile-menu").fadeOut();
        $(".mobile-menu").removeClass("is-open");
    });

    if ($(".homepage").length === 0) {
        $(".global-header").sticky({
            topSpacing: 0,
            zIndex: 50,
        });
    } else {
        if ($(window).width() < 1024) {
            $(".global-header").sticky({
                topSpacing: 0,
                zIndex: 50,
            });
        }
    }

    $(".about-sub-nav").sticky({
        topSpacing: $(".global-header").height(),
        zIndex: 10,
    });
}

export default headerInit;
