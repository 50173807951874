const homeStaffSlider = new Swiper(".home-staff-slider .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 30,
    centeredSlides: true,
    initialSlide: 1,
    navigation: {
        prevEl: ".home-staff-slider .swiper-prev",
        nextEl: ".home-staff-slider .swiper-next",
    },
    breakpoints: {
        1280: {
            slidesPerView: 3,
            spaceBetween: 20
        },
        1024: {
            slidesPerView: 2.5,
            spaceBetween: 15
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15
        }
    },
});

export default homeStaffSlider;
