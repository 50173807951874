const homeServiceSlider = new Swiper(".home-service-slider .swiper-container", {
    spaceBetween: 30,
    slidesPerView: 1.2,
    scrollbar: {
        el: ".home-service-slider .swiper-scrollbar",
        draggable: true
    },
    breakpoints: {
        1024: {
            spaceBetween: 20,
        },
        768: {
            spaceBetween: 15,
        },
    },
});

export default homeServiceSlider;
