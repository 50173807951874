export default function appendFormBtn(params) {
    try {
        var observerForm = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === "childList") {
                    console.log("abc");
                    $(".wrap-form").each(function () {
                        let formRow = $(this).find(".form-row");
                        let submitContainer = $(this).find(".frm-btnwrap");
                        $(formRow).append($(submitContainer));
                    });
                }
            });
        });

        observerForm.observe(
            document.querySelector(".form-container .ModuleContent > div"),
            {
                childList: true,
            }
        );

        $(".wrap-form").each(function () {
            let formRow = $(this).find(".form-row");
            let submitContainer = $(this).find(".frm-btnwrap");
            $(formRow).append($(submitContainer));
        });
    } catch (error) {}
}

