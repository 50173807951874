const homeFacilitySlider  = new Swiper(".home-facility-slider .swiper-container", {
    spaceBetween: 30,
    slidesPerView: 2,
    breakpoints: {
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
    },
});

export default homeFacilitySlider